featured-events-box {
    > h2 {
        margin: 0 0 15px 10px;
        text-transform: uppercase;
        color: $COLOR_V3_BEIGE_BEYOND_DARK;
    }

    .featured-events-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 425px;
        background-color: $COLOR_V3_WHITE;
        position: relative;
        border-radius: 4px;

        .events-container {
            display: flex;
            flex-direction: column;
            padding: 18px 12px;

            front-royal-spinner {
                margin: 180px auto;
            }

            &.featured,
            &.has-more,
            &.more-than-fits {
                height: 361px;
                overflow: hidden;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    pointer-events: none;
                    background-image: linear-gradient(
                        to bottom,
                        rgba(255, 255, 255, 0) 93%,
                        rgba(255, 255, 255, 1) 100%
                    );
                    width: 100%;
                    height: 100%;
                }
            }

            .no-events-found {
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: inherit;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
                font-size: 15px;
            }

            .event-container {
                margin: 0 0 23px 0;
                cursor: pointer;

                &:hover {
                    border-radius: 5px;
                    background-color: rgba(0, 0, 0, 0.02);
                }

                .event {
                    display: flex;

                    .image-container {
                        flex: 0 0 73px;
                        height: 54px;

                        img {
                            border-radius: 4px;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .content {
                        margin-left: 13px;
                        flex: 1 1;

                        html[dir="rtl"] & {
                            margin-left: 0;
                            margin-right: 13px;
                        }

                        > .event-title {
                            color: $COLOR_V3_BEIGE_FOR_TEXT;
                            font-size: 18px;
                            line-height: 21px;
                            font-weight: $FONT_WEIGHT_SEMIBOLD;
                        }

                        > .event-time,
                        .event-location {
                            color: $COLOR_V3_BEIGE_BEYOND_DARK;
                            font-size: 13px;
                            margin: 2px 0 0 0;
                        }
                    }
                }
            }
        }

        .button-container {
            display: flex;
            height: 56px;
            justify-content: center;
            align-items: center;
            button {
                height: 30px;
                margin-bottom: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                border-radius: 50px;
                font-weight: $FONT_WEIGHT_SEMIBOLD;
                font-size: 12px;
                color: $COLOR_V3_BEIGE_FOR_TEXT;
                line-height: 12px;
                text-transform: uppercase;
                padding: 10px 25px;
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                transition: 0.2s ease-in-out box-shadow;
                border: 1px solid $COLOR_V3_BEIGE;

                &:hover {
                    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
                }
            }
        }
    }
}
