@font-face {
    font-family: "ProximaNovaSoft";
    src: url("~fonts/ProximaNova-RegIt.woff");
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "ProximaNovaSoft";
    src: url("~fonts/ProximaNovaSoft-Bold.woff");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNovaSoft";
    src: url("~fonts/ProximaNovaSoft-Medium.woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNovaSoft";
    src: url("~fonts/ProximaNovaSoft-Regular.woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNovaSoft";
    src: url("~fonts/ProximaNovaSoft-Semibold.woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("~fonts/montserrat/Montserrat.woff") format("woff"),
        url("~fonts/montserrat/Montserrat.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("~fonts/montserrat/Montserrat-Bold.woff") format("woff"),
        url("~fonts/montserrat/Montserrat-Bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
}

// FIXME: there's some overlap here between the above Monsterrat font faces and these,
// but we still have references in code to these specific font families.
// See also the ShareableGraphics module.
@font-face {
    font-family: "Montserrat Medium";
    src: url("~fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "Montserrat SemiBold";
    src: url("~fonts/montserrat/Montserrat-SemiBold.ttf") format("truetype");
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "Montserrat Bold";
    src: url("~fonts/montserrat/Montserrat-Bold.woff") format("woff"),
        url("~fonts/montserrat/Montserrat-Bold.woff2") format("woff2");
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "DMSans";
    src: url("~fonts/dm-sans/DMSans.woff") format("woff"), url("~fonts/dm-sans/DMSans.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "DMSans";
    src: url("~fonts/dm-sans/DMSans-Bold.woff") format("woff"), url("~fonts/dm-sans/DMSans-Bold.woff2") format("woff2");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Lab Grotesque Regular";
    src: url("~fonts/lab-grotesque/LabGrotesque-Regular.woff") format("woff"),
        url("~fonts/lab-grotesque/LabGrotesque-Regular.woff2") format("woff2");
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "Lab Grotesque Medium";
    src: url("~fonts/lab-grotesque/LabGrotesque-Medium.woff") format("woff"),
        url("~fonts/lab-grotesque/LabGrotesque-Medium.woff2") format("woff2");
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "Lab Grotesque Bold";
    src: url("~fonts/lab-grotesque/LabGrotesque-Bold.woff") format("woff"),
        url("~fonts/lab-grotesque/LabGrotesque-Bold.woff2") format("woff2");
    font-style: normal;
    font-display: fallback;
}
