a.stream-link-box-link {
    &,
    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

.locked-tooltip {
    .tooltip-inner {
        background-color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK;
    }

    a {
        text-decoration: underline;
        color: #fff;
    }

    .tooltip-arrow {
        border-top-color: $COLOR_V3_BEIGE_BEYOND_BEYOND_DARK !important;
        left: 37px !important;
    }

    &.in {
        opacity: 1;
    }
}

@mixin stream-beta-label() {
    .beta-course {
        display: none;
    }

    &.beta {
        @media (max-width: $screen-xs-max) {
            min-height: 50px;
            position: relative;

            .beta-course {
                position: absolute;
                display: block;
                left: 0px;
                top: 33px;
                font-size: 9px;
                line-height: 12px;
                padding-top: 2px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    left: auto;
                    right: 0px;
                }
            }
        }
    }
}

.stream-wrapper {
    float: right;
}

.stream-link-box {
    position: relative;

    .course-ribbon {
        position: absolute;
        left: -5px;
        top: -5px;
        z-index: 1;
        overflow: hidden;
        width: 62px;
        height: 62px;
        text-align: right;

        // rtl-language overrides
        html[dir="rtl"] & {
            left: auto;
            right: -5px;
            text-align: left;
        }

        span {
            position: absolute;
            width: 100px;
            display: block;
            top: 11px;
            left: -28px;
            font-size: 10px;
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            line-height: 20px;
            transform: rotate(-45deg);
            background: $COLOR_V3_CORAL;

            &:before {
                content: "";
                position: absolute;
                left: 9px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid $COLOR_V3_CORAL_DARK;
                border-right: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-top: 3px solid $COLOR_V3_CORAL_DARK;
            }
            &:after {
                content: "";
                position: absolute;
                right: 11px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid transparent;
                border-right: 3px solid $COLOR_V3_CORAL_DARK;
                border-bottom: 3px solid transparent;
                border-top: 3px solid $COLOR_V3_CORAL_DARK;
            }

            // rtl-language overrides
            html[dir="rtl"] & {
                transform: rotate(45deg);
                left: auto;
                right: -28px;

                &:before {
                    left: 11px;
                }

                &:after {
                    right: 9px;
                }
            }
        }
    }

    .course-box,
    .course-box .icon {
        transition: opacity 0.5s ease;
        opacity: 1;
    }

    // Course Box-Specific styles
    .grid-box.course-box {
        // Hover state
        .no-touchevents &:hover,
        .no-touchevents a:hover & {
            .stream-link-icon {
                box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
            }
            .stat-group.launch:not(.locked) {
                text-shadow: 1px 1px 4px rgba($COLOR_V3_BLACK, 0.5);
            }
        }

        &:active,
        a:active & {
            .content-box .description:after {
                @include gradient-vertical(rgba($COLOR_V3_BEIGE_LIGHTEST, 0), rgba($COLOR_V3_BEIGE_LIGHTEST, 1));
            }

            .content-box .title:after {
                @include gradient-vertical(rgba($COLOR_V3_BEIGE_LIGHTEST, 0), rgba($COLOR_V3_BEIGE_LIGHTEST, 1));
            }
        }

        // Content box overrides
        .content-box {
            &.list-view {
                @media (min-width: $screen-sm-min) {
                    display: flex;
                    justify-content: space-between;
                }

                .title {
                    @include stream-beta-label();

                    // Make room for course icon
                    @media (max-width: $screen-xs-max) {
                        position: relative;
                        padding-left: 47px;
                        min-height: 43px;
                        display: table;
                        word-wrap: break-word;

                        // Add some space between title and description
                        margin-bottom: 5px;

                        // rtl-language overrides
                        html[dir="rtl"] & {
                            padding-left: 0px;
                            padding-right: 47px;
                            line-height: 30px;
                        }

                        @-moz-document url-prefix() {
                            height: 43px;
                        }

                        .title-text {
                            display: table-cell;
                            vertical-align: middle;
                        }

                        // Icon float to the left of the title
                        .icon {
                            position: absolute;
                            display: block;
                            left: 0px;
                            top: 1px;
                            height: 40px;
                            width: auto;

                            // rtl-language overrides
                            html[dir="rtl"] & {
                                left: auto;
                                right: 0px;
                            }
                        }
                    }
                    @media (min-width: $screen-sm-min) {
                        word-wrap: break-word;
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        height: 56px;
                        margin: 5px 0;
                        color: $COLOR_V3_BEIGE_FOR_TEXT;

                        // rtl-language overrides
                        html[dir="rtl"] & {
                            padding-right: 0px;
                            padding-left: 0px;

                            // This is a little sketchy, but it seems like the simplest change to fix the display of these titles.
                            // In theory, we should have to increase line height, too, but I haven't see any Arabic titles in practice
                            // that have diacritics such that multiple lines overlap at desktop sizes.
                            overflow: visible;
                        }

                        // Icon float to the left of the title
                        .icon {
                            height: 30px;
                            width: 30px;
                            margin-right: 10px;

                            // rtl-language overrides
                            html[dir="rtl"] & {
                                margin-right: 0px;
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }

            &:not(.list-view) {
                .title {
                    @include stream-beta-label();

                    // Make room for course icon
                    @media (max-width: $screen-xs-max) {
                        position: relative;
                        padding-left: 47px;
                        min-height: 43px;
                        display: table;
                        word-wrap: break-word;

                        // Add some space between title and description
                        margin-bottom: 5px;

                        // rtl-language overrides
                        html[dir="rtl"] & {
                            padding-left: 0px;
                            padding-right: 47px;
                            line-height: 30px;
                        }

                        @-moz-document url-prefix() {
                            height: 43px;
                        }

                        .title-text {
                            display: table-cell;
                            vertical-align: middle;
                        }

                        // Icon float to the left of the title
                        .icon {
                            position: absolute;
                            display: block;
                            left: 0px;
                            top: 1px;
                            height: 40px;
                            width: auto;

                            // rtl-language overrides
                            html[dir="rtl"] & {
                                left: auto;
                                right: 0px;
                            }
                        }
                    }

                    // Make room for course icon and favorite ribbon at larger sizes
                    @media (min-width: $screen-sm-min) {
                        position: relative;
                        padding-left: 60px;
                        padding-right: 25px;
                        word-wrap: break-word;
                        display: block;

                        // rtl-language overrides
                        html[dir="rtl"] & {
                            padding-right: 60px;
                            padding-left: 25px;

                            // This is a little sketchy, but it seems like the simplest change to fix the display of these titles.
                            // In theory, we should have to increase line height, too, but I haven't see any Arabic titles in practice
                            // that have diacritics such that multiple lines overlap at desktop sizes.
                            overflow: visible;
                        }

                        // Icon float to the left of the title
                        .icon {
                            position: absolute;
                            display: block;
                            left: 0px;
                            top: 3px;
                            height: 50px;
                            width: auto;

                            // rtl-language overrides
                            html[dir="rtl"] & {
                                left: auto;
                                right: 0px;
                            }
                        }

                        @include clamp-multiple-lines-fade-out(3, $titleLineHeightDesktop, rgb(255, 255, 255));
                    }
                    @media screen and (min-width: $screen-sm-min) and (-webkit-min-device-pixel-ratio: 0) {
                        @include clamp-multiple-lines-ellipsis(3, $titleLineHeightDesktop, rgb(255, 255, 255));
                    }

                    // Add a new smaller size only for sm
                    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                        font-size: $titleFontSizeTablet;
                        line-height: $titleLineHeightTablet;

                        @include clamp-multiple-lines-fade-out(3, $titleLineHeightTablet, rgb(255, 255, 255));
                    }
                    @media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) and (-webkit-min-device-pixel-ratio: 0) {
                        @include clamp-multiple-lines-ellipsis(3, $titleLineHeightTablet, rgb(255, 255, 255));
                    }

                    // At super wide, shorten the title height
                    @media (min-width: $screen-lg-min) {
                        @include clamp-multiple-lines-fade-out(2, $titleLineHeightDesktop, rgb(255, 255, 255));
                    }
                    @media screen and (min-width: $screen-lg-min) and (-webkit-min-device-pixel-ratio: 0) {
                        @include clamp-multiple-lines-ellipsis(2, $titleLineHeightDesktop, rgb(255, 255, 255));
                    }
                }
            }

            // Horizontal Rule
            hr.hr-course {
                color: $COLOR_V3_BEIGE_MIDDARK;
                width: auto;
                // Counter-act default padding (mobile)
                margin-left: -10px;
                margin-right: -10px;
                margin-bottom: 10px;

                // Padding at larger sizes (iPad Portrait and larger)
                @media (min-width: $screen-sm-min) {
                    margin-left: -15px;
                    margin-right: -15px;
                }
            }

            .description {
                $descr-font-size: 15px;
                $descr-line-height: 17px;
                $descr-font-size-ar: 15px;
                $descr-line-height-ar: 24px;

                color: $COLOR_V3_BEIGE_DARKER;
                margin-bottom: 0px;

                // ltr languages
                html[dir="ltr"] & {
                    font-size: $descr-font-size;
                    line-height: $descr-line-height;
                    @include clamp-multiple-lines(2, $descr-line-height, rgb(255, 255, 255));
                }

                // rtl-language overrides
                html[dir="rtl"] & {
                    font-size: $descr-font-size-ar;
                    line-height: $descr-line-height-ar;
                    @include clamp-multiple-lines(2, $descr-line-height-ar, rgb(255, 255, 255));
                }
            }

            // Topic tags
            .topics-box {
                position: relative;
                display: block;
                margin-top: 2px;
                margin-bottom: 12px;
                min-height: 22px;

                .available-offline-icon {
                    position: absolute;
                    right: 23px;
                    bottom: 0px;

                    html[dir="rtl"] & {
                        left: 23px;
                        right: auto;
                    }
                }
            }

            // topics
            .topic {
                display: inline-block;
                color: $COLOR_V3_BEIGE_DARKER;
                font-size: 10px;
                line-height: 18px;
                padding: 0px 5px;
                margin-right: 3px;
                text-transform: uppercase;
                border: 1px solid $COLOR_V3_BEIGE_MIDDARK;
                border-radius: 2px;

                // rtl-language overrides
                html[dir="rtl"] & {
                    margin-right: 0px;
                    margin-left: 3px;
                }

                &.beta-course {
                    color: $COLOR_V3_WHITE;
                    padding: 0px 8px;
                    border: 1px solid $COLOR_V3_BETA_GREY;
                    background-color: $COLOR_V3_BETA_GREY;
                    border-radius: 10px;
                }
            }

            // Lock Icon
            .icon.lock {
                position: absolute;
                width: 57px;
                height: 65px;

                bottom: 50%;
                right: 50%;
                transform: translateX(50%) translateY(50%);

                @media (min-width: $screen-sm-min) {
                    bottom: 68px;
                    right: 8px;
                    width: 26px;
                    height: 30px;
                    transform: none;
                }
            }

            .stream-link-icon {
                display: flex;
                justify-content: center;
                align-self: center;
                align-items: center;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                background-color: $COLOR_V3_WHITE;
                color: $COLOR_V3_BEIGE_BEYOND_DARK;
                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                transition: 0.2s ease-in-out box-shadow;
                border: 1px solid $COLOR_V3_BEIGE;

                &.fa-arrow-right {
                    html[dir="rtl"] & {
                        &::before {
                            // swap to left icon
                            content: "\f060";
                        }
                    }
                }

                &.next_unstarted {
                    color: $COLOR_V3_GREEN;
                }
                &.inProgress {
                    color: $COLOR_V3_PURPLE;
                }
                &.completed {
                    color: $COLOR_V3_CORAL;
                }
            }
        }

        // Bottom header that contains start button, etc.
        .header-box.bottom {
            @media (min-width: $screen-sm-min) {
                display: block;
                padding: 5px 15px;
                height: 60px;
                line-height: normal;
                overflow: hidden;

                // Stat group overrides
                .stat-group {
                    text-align: left;

                    // rtl-language overrides
                    html[dir="rtl"] & {
                        text-align: right;
                    }

                    .caption {
                        white-space: nowrap;
                        font-size: 10px;

                        html:lang(es) & {
                            font-size: 8px;
                        }
                    }
                    .big-number,
                    .total {
                        line-height: 35px;
                    }
                    .caption,
                    .big-number,
                    .fraction,
                    .total {
                        color: $COLOR_V3_WHITE;
                    }
                    &.completed {
                        line-height: 12px;
                        .date,
                        .year {
                            font-size: 14px;
                            white-space: nowrap;
                            text-transform: uppercase;
                        }
                    }
                    &.launch {
                        font-size: 22px;
                        line-height: 50px;
                        text-align: center;
                        display: block;
                        border-left: 1px solid $COLOR_V3_WHITE;
                        transition: text-shadow 0.5s ease;
                        padding-left: 10px;
                        z-index: 1;
                        float: right; // handles situations where total time isn't displayed

                        // completed exams get a smaller font to fit more text
                        &.exam-complete {
                            font-size: 20px;
                        }

                        // rtl-language overrides
                        html[dir="rtl"] & {
                            border-left: none;
                            border-right: 1px solid $COLOR_V3_WHITE;
                            padding-right: 10px;
                            padding-left: 0px;
                        }

                        html:lang(es) & {
                            font-size: 18px;
                        }
                    }
                    &.playlist-track-stat {
                        .caption,
                        .big-number,
                        .fraction,
                        .total,
                        .date {
                            color: $COLOR_V3_BEIGE_BEYOND_DARK;
                        }
                        &.in_progress {
                            .caption,
                            .big-number,
                            .fraction,
                            .total,
                            .date {
                                color: $COLOR_V3_PURPLE;
                            }
                        }
                        &.completed {
                            .caption,
                            .big-number,
                            .fraction,
                            .total,
                            .date {
                                color: $COLOR_V3_BEIGE_DARK;
                            }
                        }
                    }
                }
            }

            // At slightly larger size, show more
            @media (min-width: 860px) {
                .stat-group {
                    .caption {
                        font-size: 12px;

                        html:lang(es) & {
                            font-size: 10px;
                        }
                    }
                    &.completed {
                        line-height: 15px;
                        .date,
                        .year {
                            font-size: 19px;
                        }
                    }
                    &.launch {
                        // exams have longer text, don't get the larger font when complete
                        &:not(.exam-complete) {
                            font-size: 25px;
                        }

                        html:lang(es) & {
                            font-size: 23px;
                        }
                    }
                }
            }

            @media (min-width: $screen-md-min) {
                .stat-group .caption {
                    html:lang(es) & {
                        font-size: 12px;
                    }
                }
            }

            .available-offline-icon {
                position: absolute;
                right: 23px;
                bottom: 15px;

                html[dir="rtl"] & {
                    left: 23px;
                    right: auto;
                }
            }
        }

        // Mobile-only: display right arrow
        @media (max-width: $screen-xs-max) {
            padding-right: 40px;
            position: relative;

            // rtl-language overrides
            html[dir="rtl"] & {
                padding-right: 10px;
                padding-left: 40px;
            }
        }

        // Grid Box Style Overrides based on Course State
        &.not_started {
            .header-box,
            .description-box,
            .right-arrow {
                background-color: $COLOR_V3_GREEN;
            }
        }

        &.in_progress {
            .header-box,
            .description-box,
            .right-arrow {
                background-color: $COLOR_V3_PURPLE;
            }
        }

        &.completed {
            .header-box,
            .description-box,
            .right-arrow {
                background-color: $COLOR_V3_CORAL;
            }
        }

        &.keep-learning {
            .header-box,
            .description-box,
            .right-arrow {
                background-color: $COLOR_V3_YELLOW;
            }
        }
        &.locked {
            .header-box,
            .description-box,
            .right-arrow {
                background-color: $COLOR_V3_BEIGE_MIDDARK;
            }
        }

        @media (min-width: $screen-sm-min) {
            padding-top: 10px;

            // At desktop sizes, hide right arrow
            &:after {
                display: none;
            }
        }
    }

    &.list-view {
        .grid-box.course-box {
            @media (min-width: $screen-sm-min) {
                background-color: #f5f3f1;
                padding-top: 0;
                padding-left: 30px;
                padding-right: 30px;
            }

            html[dir="rtl"] & {
                padding-left: 30px;
                padding-right: 30px;
            }

            .available-offline-icon {
                width: 18px;
                margin-bottom: 2px;
                margin-left: 9px;

                html[dir="rtl"] & {
                    @media (min-width: $screen-sm-min) {
                        margin-left: 0;
                        margin-right: 9px;
                    }
                }
            }
        }
    }

    &.offline_and_not_available,
    &.coming-soon {
        @media (min-width: $screen-sm-min) {
            &.list-view {
                opacity: 50%;
                .grid-box.course-box {
                    .stream-link-icon {
                        display: none;
                    }
                }
            }
        }
    }
}

// Coming Soon and Locked courses shouldn't show click cursor
stream-link-box > a.unclickable {
    // At larger sizes, don't show a cursor except on the notify button
    @media (min-width: $screen-sm-min) {
        cursor: auto;
    }
}

// Coming Soon overrides
stream-link-box > a.coming-soon {
    > .stream-link-box {
        .grid-box.course-box {
            // Hover state
            &,
            .no-touchevents &:hover,
            .no-touchevents a:hover & {
                .stat-group.launch {
                    text-shadow: none !important;
                }
                .header-box,
                .description-box,
                .right-arrow {
                    background: $COLOR_V3_BEIGE_MIDDARK !important;
                }
                .header-box:after {
                    display: none;
                }
            }

            @media (min-width: $screen-sm-min) {
                // Active state
                &:active,
                a:active & {
                    background-color: $COLOR_V3_WHITE;

                    .content-box .description:after {
                        @include gradient-vertical(rgba($COLOR_V3_WHITE, 0), rgba($COLOR_V3_WHITE, 1));
                    }
                }
            }

            .launch {
                border-left: none !important;
                padding-left: 15px !important;
                text-align: left !important;
                padding-right: 0px !important;
            }

            .notify-me {
                text-align: right !important;
                padding-left: 0px !important;
                float: right;

                button.flat {
                    padding-top: 6px;
                    padding-bottom: 5px;
                    margin-top: 5px;
                    margin-bottom: 5px;

                    &:focus {
                        background-color: transparent;
                    }
                    .no-touchevents &:hover {
                        background-color: $COLOR_V3_BEIGE_DARKER;
                    }
                    .no-touchevents &:active,
                    .touchevents &:active {
                        background-color: $COLOR_V3_BEIGE_DARKEST;
                    }

                    @media (max-width: $screen-sm-max) {
                        font-size: 15px !important;
                        line-height: $line-height-base * 18px;
                        padding-top: 3px;
                        padding-bottom: 2px;
                        margin-top: 8px;
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
}
