.student-dashboard {
    display: block;
    position: relative;

    // shared styles
    .complete-header-icon {
        background-image: url("~images/complete_playlist_header_icon.png");
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .mba-icon {
        background-image: url("~images/shield_quantic.png");
        background-size: 100%;
        background-repeat: no-repeat;

        &.valar {
            background-image: url("~images/shield_valar.png");
        }

        &.smartly {
            background-image: url("~images/shield.png");
        }
    }

    .main-column {
        margin-bottom: 0px;

        & > *:empty {
            display: none;
        }

        @media (min-width: $screen-sm-max) {
            margin-bottom: 15px;
        }

        student-dashboard-learning-box {
            @media (min-width: $screen-sm-min) {
                display: block;
                margin-bottom: 4px;
            }

            &.sdv1 {
                @media (min-width: $screen-sm-min) {
                    margin-bottom: 35px;
                }
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        padding-top: 0px;

        // if showing the playlists view on mobile, hide other stuff (it's like a modal view)
        &.showing-playlists {
            .courses-container {
                display: none;
            }
            dashboard-footer {
                display: none;
            }
        }
    }

    // retained here for easy removal ...
    .blue-ocean-share-box {
        background-color: #1e5479;
        background-image: url("~vectors/underwater_card_bg.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center -10px;
        color: #b8e2e1;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 20px;
        height: 241px;

        .text-container {
            width: 255px;
            height: 72px;
            margin: 35px auto;
            margin-bottom: 20px;
        }

        .share-container {
            margin: 0 auto;
            .share-button-text {
                margin-left: 17px;
                text-align: left;
            }

            .share-on-facebook {
                @include share_on_facebook($COLOR_V3_CORAL, $COLOR_V3_WHITE);
            }

            .share-on-linkedin {
                @include share_on_linkedin($COLOR_V3_CORAL, $COLOR_V3_WHITE);
            }

            .share-on-twitter {
                @include share_on_twitter($COLOR_V3_CORAL, $COLOR_V3_WHITE);
            }

            .share-on-email {
                @include share_on_email($COLOR_V3_CORAL, $COLOR_V3_WHITE);
            }
        }

        @media (min-width: $screen-sm-min) {
            // Firefox has a fixed height header and more space for truncated descripion text fade-out
            @-moz-document url-prefix() {
                height: 262px;
            }
        }

        @media (min-width: $screen-md-min) {
            height: 247px;

            // Firefox has a fixed height header and more space for truncated descripion text fade-out
            @-moz-document url-prefix() {
                height: 270px;
            }
        }

        @media (min-width: $screen-lg-min) {
            height: 219px;

            // Firefox has a fixed height header and more space for truncated descripion text fade-out
            @-moz-document url-prefix() {
                height: 242px;
            }

            .text-container {
                width: 340px;
                height: 55px;
                margin-top: 40px;
                margin-bottom: 10px;
            }
        }
    }

    // If someone clicks to unbookmark a course in the
    // bookmarked courses section, then fade it out
    .bookmarked-courses {
        .stream-link-box:not(.favorite) {
            .course-box {
                opacity: 0.6;
            }
        }
    }

    // Margins around sections
    .courses-group {
        margin-bottom: 10px;
        @media (min-width: $screen-sm-min) {
            margin-bottom: 0px;
        }
    }

    // Top Nav Box-Specific Styles
    .top-nav-box {
        .title {
            // Clamp to 3 lines
            @media (min-width: $screen-sm-min) {
                font-size: $titleFontSizeDesktop - 3px;
                line-height: $titleLineHeightDesktop - 3px;
                @include clamp-multiple-lines-fade-out(3, $titleLineHeightDesktop - 3px, rgb(255, 255, 255));
            }
            @media screen and (min-width: $screen-sm-min) and (-webkit-min-device-pixel-ratio: 0) {
                font-size: $titleFontSizeDesktop;
                line-height: $titleLineHeightDesktop;
                @include clamp-multiple-lines-ellipsis(3, $titleLineHeightDesktop, rgb(255, 255, 255));
            }
        }
        .content-box {
            img {
                display: block;
                margin: 0 auto;
            }

            @media (min-width: $screen-sm-min) {
                height: 154px;
            }

            @media (max-width: $screen-xs-max) {
                height: auto;
            }
        }
    }

    .beige-message {
        display: block;
        position: relative;
        color: $COLOR_V3_BEIGE_DARKER;
        margin-bottom: 0px;

        a {
            color: $COLOR_V3_BLUE;
        }

        strong {
            font-weight: $FONT_WEIGHT_SEMIBOLD;
            font-style: normal;
        }

        @media (max-width: $screen-xs-max) {
            font-size: 15px;
            font-weight: $FONT_WEIGHT_NORMAL;
            line-height: 21px;
            margin-bottom: 14px;
            text-align: center;
        }

        @media (min-width: $screen-sm-min) {
            font-size: 17px;
            line-height: 21px;
            margin-bottom: 25px;
        }

        &.no-activated {
            @media (min-width: $screen-md-min) {
                width: 595px;
                margin-left: auto;
                margin-right: auto;
            }
        }

        &.congrats,
        &.recommend,
        &.no-courses,
        &.all-courses-complete {
            @media (min-width: $screen-sm-min) {
                text-align: center;
            }
        }
    }
}
